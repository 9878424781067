import { createContext } from 'react'
import { DEFAULT_STATE, useAppState } from './hooks/useAppState.js'
import './App.css';
import Page from './page/index';
import 'bootstrap/dist/css/bootstrap.min.css';

export const AppStateContext = createContext([DEFAULT_STATE, (s) => { }])

function App() {

  const [appState, setAppState] = useAppState()

  return (
    <AppStateContext.Provider value={[appState, setAppState]}>
      <div className="App">
        <Page />
      </div>
    </AppStateContext.Provider>
  );
}

export default App;
