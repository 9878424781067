import React, { SetStateAction } from 'react'
import {
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  TextField,
  FilterOptionsState,
  Chip,
  AutocompleteChangeReason,
  AutocompleteChangeDetails
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'

interface Props {
  /**
   * Array of options.
   */
  data: any[]
  defaultValue?: any[]
  disablePortal?: boolean | undefined
  disableCloseOnSelect?: boolean | undefined
  clearOnEscape?: boolean | undefined
  disableClearable?: boolean | undefined
  includeInputInList?: boolean | undefined
  disableListWrap?: boolean | undefined
  /**
   * If `true`, the popup will open on input focus.
   * @default false
   */
  openOnFocus?: boolean | undefined
  autoHighlight?: boolean | undefined
  autoSelect?: boolean | undefined
  disabled?: boolean | undefined
  blurOnSelect?: boolean | undefined
  clearOnBlur?: boolean | undefined
  selectOnFocus?: boolean | undefined
  /**
   * If `true`, the component becomes readonly. It is also supported for multiple tags where the tag cannot be deleted.
   * @default false
   */
  readOnly?: boolean | undefined
  multiple?: boolean | undefined
  inputWidth?: string | '100%'
  groupBy?: (option: any) => string
  getOptionLabel?: (option: any) => string | any
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode
  renderTags?: any
  getOptionDisabled?: (option: any) => boolean
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  limitTags?: number
  textFieldLabel?: string
  loading?: boolean | undefined
  /**
   * Callback fired when the popup requests to be opened.
   * Use in controlled mode (see open).
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   */
  onOpen?: (event: React.SyntheticEvent) => void
  textFieldChangeHandler?: (event: React.SyntheticEvent) => void
  handleSelectChange?: (event: React.SyntheticEvent<Element, Event>, value: string[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string> | undefined) => void;
  /**
   * If `true`, the component is shown.
   */
  open?: boolean

  /**
   * Callback fired when the popup requests to be closed.
   * Use in controlled mode (see open).
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {string} reason Can be: `"toggleInput"`, `"escape"`, `"selectOption"`, `"removeOption"`, `"blur"`.
   */
  onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void
  onDelete?: (labelToBeDeleted: string) => void
  onClear?: () => void
  value?: any[]
  setValue?: React.Dispatch<SetStateAction<any>>
  defualtValues?: any[]
  onChange?: (event: any, value: string | null) => void
  placeholder?: string
  label?: string
  /**
   * Used to determine if the option represents the given value.
   * Uses strict equality by default.
   * ⚠️ Both arguments need to be handled, an option can only match with one value.
   *
   * @param {T} option The option to test.
   * @param {T} value The value to test against.
   * @returns {boolean}
   */
  isOptionEqualToValue?: (option: any, value: any) => boolean
  /**
   * A function that determines the filtered options to be rendered on search.
   *
   * @param {any} options The options to render.
   * @param {object} state The state of the component.
   * @returns {any}
   */
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[]
}


const Autosuggested: React.FC<Props> = ({
  data,
  disablePortal,
  disableCloseOnSelect,
  clearOnEscape,
  disableClearable,
  includeInputInList,
  disableListWrap,
  openOnFocus,
  autoHighlight,
  autoSelect,
  disabled,
  blurOnSelect,
  clearOnBlur,
  selectOnFocus,
  readOnly,
  inputWidth,
  multiple,
  groupBy,
  getOptionLabel,
  renderGroup,
  getOptionDisabled,
  renderOption,
  renderTags,
  limitTags,
  loading,
  open,
  onOpen,
  onClose,
  handleSelectChange,
  defaultValue,
  value,
  setValue,
  onChange,
  onDelete,
  onClear,
  placeholder,
  textFieldLabel,
  isOptionEqualToValue,
  filterOptions,
  textFieldChangeHandler
}) => {
  return (
    <Autocomplete
      disablePortal={disablePortal}
      disableCloseOnSelect={disableCloseOnSelect}
      clearOnEscape={clearOnEscape}
      disableClearable={disableClearable}
      includeInputInList={includeInputInList}
      disableListWrap={disableListWrap}
      openOnFocus={openOnFocus}
      autoHighlight={autoHighlight}
      autoSelect={autoSelect}
      disabled={disabled}
      blurOnSelect={blurOnSelect}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      readOnly={readOnly}
      id="auto-suggested"
      options={data}
      sx={{ 
        width: inputWidth
      }}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      open={open}
      onChange={!multiple ? onChange : handleSelectChange}
      onOpen={onOpen}
      onClose={onClose}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          label={textFieldLabel}
          onChange={textFieldChangeHandler}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ?? false ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      renderGroup={renderGroup}
      renderTags={(value: readonly string[], getTagProps) => {
        return value
          .map((option: string, index: number) => {
            return (
              option && <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })}
                onDelete={e => {
                  onDelete?.(option)
                }}
              />
            )
          })
      }}
      clearIcon={
        <ClearIcon fontSize="small" onClick={onClear} />
      }
      getOptionDisabled={getOptionDisabled}
      renderOption={renderOption}
      limitTags={limitTags}
      defaultValue={defaultValue}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={filterOptions}
    />
  )
}

Autosuggested.defaultProps = {
  disablePortal: false,
  disableCloseOnSelect: false,
  clearOnEscape: false,
  disableClearable: false,
  includeInputInList: false,
  disableListWrap: false,
  openOnFocus: false,
  autoHighlight: false,
  autoSelect: false,
  blurOnSelect: false,
  disabled: false,
  clearOnBlur: false,
  selectOnFocus: false,
  readOnly: false,
  multiple: false,
  inputWidth: '100%',
  loading: false,
  onClear: () => { }
  // value: []
}

export default Autosuggested
