/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import moment from 'moment'

const isNumericInput = (event: any) => {
   const key = event.keyCode
   return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
   )
}

const isModifierKey = (event: any) => {
   const key = event.keyCode
   return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
         (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
   )
}

export const enforceFormat = (event: any) => {
   // Input must be of a valid number format or a modifier key, and not longer than ten digits
   if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault()
   }
}

export const formatToPhone = (event) => {
   if (isModifierKey(event)) {
      return
   }

   const target = event.target
   target.value = event.target.value.replace('+1 ', '')
   const input = event.target.value.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
   const zip = input.substring(0, 3)
   const middle = input.substring(3, 6)
   const last = input.substring(6, 10)

   if (input.length > 6) {
      target.value = `+1 (${zip}) ${middle} - ${last}`
   } else if (input.length > 3) {
      target.value = `+1 (${zip}) ${middle}`
   } else if (input.length > 0) {
      target.value = `+1 (${zip}`
   }
}

export const formatToSSN = (event) => {
   if (isModifierKey(event)) {
      return
   }

   const target = event.target
   //   target.value = event.target.value.replace('+1 ', '')
   const input = event.target.value.replace(/\D/g, '').substring(0, 9) // First ten digits of input only
   const zip = input.substring(0, 3)
   const middle = input.substring(3, 5)
   const last = input.substring(5, 9)

   if (input.length > 5) {
      target.value = `${zip}-${middle}-${last}`
   } else if (input.length > 3) {
      target.value = `${zip}-${middle}`
   } else if (input.length > 0) {
      target.value = `${zip}`
   }
}

export const maskPhoneNumbertoUS = (event) => {
   enforceFormat(event)
   formatToPhone(event)
   return event.target.value
}

export const maskSSNNumber = (event) => {
   enforceFormat(event)
   formatToSSN(event)

   return event.target.value
}

export const validateEmail = (emailAddress) => {
   // eslint-disable-next-line
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return ''
   } else {
      return 'invalid format'
   }
}

export const validateSSN = (ssn) => {
   if (ssn.length < 11) {
      return 'invalid format'
   }
   return ''
}

export const validateForm = (form) => {
   let isNotHavingError = true
   form.map((e) => {
      if (e.errorMessage || (e.required && (e.value === '' || e.value === null))) isNotHavingError = false
   })
   return isNotHavingError
}

export const checkIsNumber = (val) => {
   if (!val) return true

   const last = val.split('')[val.length - 1]

   if (last === ' ' || last === '' || last === '-' || last === '(') return true

   let x = last
   try {
      x = !isNaN(Number(last))
   } catch (error) { }
   return x;
}

export function calculateAge(dob, isEditPatientClicked) {
   if (!dob || isEditPatientClicked) return true

   const diff = Math.floor(moment().diff(moment(dob, "YYYY-MM-DD"), 'years', true))

   if (diff < 10 && window.confirm('Are you sure? Patient is less than 10 years.')) {
      return true
   }

   if (diff >= 10) {
      return true
   }

   return false
}

export const maskingSSNNumber = (number: any) => {

   if (!number) return ''
   let target = number
   const input = number.split('-').join('')
   const zip = input.substring(0, 3).replace(/[0-9]/g, 'X')
   const middle = input.substring(3, 5).replace(/[0-9]/g, 'X')
   const last = input.substring(5, 9)

   if (input.length > 5) {
      target = `${zip}-${middle}-${last}`
   } else if (input.length > 3) {
      target = `${zip}-${middle}`
   } else if (input.length > 0) {
      target = `${zip}`
   }

   return target

   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
   // return number?.replace(/^(\d{3})(\d{2}).*/, number)
}
