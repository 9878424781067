import { Tooltip } from "@mui/material"

const ErrorTooltip = ({
    isVisible,
    title,
}: {
    isVisible: boolean
    title: string
}) => {

    if(!isVisible) return <></>

    return <>
        <Tooltip title={title} arrow><span className='incomplete-icon'><i className="fa fa-exclamation-circle" aria-hidden="true"></i></span></Tooltip>
    </>
}

export default ErrorTooltip 