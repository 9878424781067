/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react'
import { getDataWithUrl } from '../../../services/http'
import { StatesDropdownValues } from '../../../utils/utilities';
import BasicSelect from '../BasicSelect';

let timer;

function ZipCodeLookup({ data, handleSetData, isPatientForm = false }) {
   const [refSourcePostalCode, setRefSourcePostalCode] = useState(data.postalCode)
   const [loading, setLoading] = useState(false)

   const handleSourceAddressChange = (e) => {
      const { name, value } = e.target

      let newAddress: any = data;
      // eslint-disable-next-line no-debugger
      // debugger
      if (!isPatientForm && (name === 'line1' || name === 'line2') && (newAddress?.type === 'mail')) {
         newAddress = {
            ...newAddress,
            line: name === 'line1' ? [value, newAddress?.line[1]] : [newAddress?.line[0], value]
         }
      } else {
         newAddress = {
            ...newAddress,
            [name]: value
         }
      }

      handleSetData(newAddress)
   }


   useEffect(() => {
      // if (data?.postalCode?.length > 0) {
         setRefSourcePostalCode(data?.postalCode);
      // }
   }, [data])

   useEffect(() => {
      if (loading) {
         setTimeout(() => {
            /** close loader after ideal timeout */
            if (loading) {
               setLoading(false)
            }
         }, 10000);
      }
   }, [loading])


   const handleSourceZipChange = (e) => {
      e.preventDefault()
      const { value } = e.target
      const postalVal = value.slice(0, 5)

      let newAddress: any = data
      newAddress = {
         ...newAddress,
         postalCode: postalVal
      }
      setRefSourcePostalCode(postalVal)
   }

   return (
      <div className="form-group row m-0">
         <input
            type="text"
            className="form-control col-md-12 mb-1"
            name="line1"
            placeholder="Address 1..."
            value={data.line1}
            onChange={(e) => handleSourceAddressChange(e)}
         />
         <input
            type="text"
            className="form-control col-md-12 mb-1"
            name="line2"
            placeholder="Address 2...."
            value={data.line2}
            onChange={(e) => handleSourceAddressChange(e)}
         />
         <div className="address-wrapper">
            <input
               type="text"
               className="form-control col-md-4 mr-1"
               name="city"
               placeholder="City..."
               value={data.city}
               onChange={handleSourceAddressChange} />
            <BasicSelect
               name="state"
               label="state"
               value={data.state}
               defaultOption="State..."
               options={StatesDropdownValues}
               handleChange={handleSourceAddressChange}
               showLabelSection={false}
            />
            <input
               type="text" pattern="[0-9]{5}"
               className="form-control col-md-4 ml-1"
               name="postalCode"
               placeholder="Zip code...."
               value={refSourcePostalCode?.slice(0, 5)}
               onChange={handleSourceAddressChange}
               maxLength={5}
            />
         </div>
         {
            loading &&
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={loading}
               onClick={e => setLoading(false)}
            >
               <CircularProgress color="inherit" />
            </Backdrop>
         }
      </div>
   )
}

export default ZipCodeLookup
