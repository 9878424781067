/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { useEffect, useRef, useState } from 'react';
import PatientDetails from './FormSteps/PatientDetails';
import InsuranceDetails from './FormSteps/InsuranceDetails';
import TreatmentDetails from './FormSteps/TreatmentDetails';
import Attachments from './FormSteps/Attachments';
import ReferralDetails from './FormSteps/ReferralDetails';
import { AttachmentFormTypes, FullFormProps, InsuranceFormTypes, PatientFormTypes, ReferralFormTypes, tabNameTypes, TreatmentFormTypes } from '../types/formTypes';
import Modal from '../component/common/Modal/index';
import { ATTACHMENT_TAB_NAME, INSURANCE_TAB_NAME, PATIENT_TAB_NAME, REFERRAL_TAB_NAME, TREATMENT_TAB_NAME } from '../utils/utilities';
import './page.css'
import { submitPayload } from '../types/submitRequestType';
import { postMethodWithUploadURL } from '../services/http';
import ErrorTooltip from '../component/common/ErrorTooltip';
import SnackbarComponent from '../component/common/SnackBar';
import ReCAPTCHA from "react-grecaptcha"
import { GoogleReCaptchaV2, postPatientReferralApi, switchGoogleCaptcha } from '../utils/query.config';

const initialData = {
   patient: {
      firstName: '',
      middleName: '',
      lastName: '',
      genderId: '',
      birthDate: '',
      raceId: '',
      ethnicityId: '',
      email: '',
      address: {
         type: 'mail',
         line1: '',
         line2: '',
         postalCode: '',
         city: '',
         state: '',
      }
   },
   insurance: {
      insuranceName: '',
      insuranceId: '',
      effectiveDate: ''
   },
   treatment: {
      isPatientNew: '',
      dialysisFirstDay: '',
      accessType: '',
      modality: '',
      preferredTreatmentDays: [],
      preferredTreatmentTime: [],
      preferredTreatmentCenter1: '',
      preferredTreatmentCenter2: '',
      preferredTreatmentCenter3: '',
      reasonForDialysis: '',
      clientId: '',
   },
   attachment: {
      faceSheet: [],
      demographicsSheet: [],
      hepB: [],
      hAndP: [],
      labWorkup: [],
      dischargeReport: [],
      other: []
   },
   referral: {
      providerEntity: '',
      nephrologist: '',
      phone: '',
      senderFirstName: '',
      senderLastName:'',
      senderTitle: ''
   }
}


const ReferralForm = () => {
   const [triggerPatientSubmitAction, toggleTriggerPatientSubmitAction] = useState<boolean>(false)
   const [triggerInsuranceSubmitAction, toggleTriggerInsuranceSubmitAction] = useState<boolean>(false)
   const [triggerTreatmentSubmitAction, toggleTriggerTreatmentSubmitAction] = useState<boolean>(false)
   const [triggerAttachmentSubmitAction, toggleTriggerAttachmentSubmitAction] = useState<boolean>(false)
   const [triggerReferralSubmitAction, toggleTriggerReferralSubmitAction] = useState<boolean>(false)

   const [triggerPatientResetForm, toggleTriggerPatientResetForm] = useState<boolean>(false)
   const [triggerInsuranceResetForm, toggleTriggerInsuranceResetForm] = useState<boolean>(false)
   const [triggerTreatmentResetForm, toggleTriggerTreatmentResetForm] = useState<boolean>(false)
   const [triggerAttachmentResetForm, toggleTriggerAttachmentResetForm] = useState<boolean>(false)
   const [triggerReferralResetForm, toggleTriggerReferralResetForm] = useState<boolean>(false)

   const [fullFormData, setFullFormData] = useState<FullFormProps>(initialData)
   const [loading, setLoading] = useState(false)
   const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false)
   const [showConfirmSubmit, setShowConfirmSubmit] = useState<boolean>(false)
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
   const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)
   const [errorContainingTabNames, setErrorContainingTabNames] = useState<tabNameTypes[] | []>([])
   const [hasSubmitApiFailed, setHasSubmitApiFailed] = useState<string>('')
   const [ReCaptchaValue, setReCaptchaValue] = useState<string>('')
   const [uploadSnackBar, setUploadSnackbar] = useState<{ message: string, severity: 'error' | 'success' }>({ message: '', severity: 'success' })
   const [isFormHavingError, setIsFormHavingError] = useState<boolean>(false)

   // const captcha = useRef<ReCAPTCHA>(null);

   useEffect(() => {
      if (isSubmitClicked && !triggerPatientSubmitAction &&
         !triggerInsuranceSubmitAction && !triggerTreatmentSubmitAction &&
         !triggerAttachmentSubmitAction && !triggerReferralSubmitAction) {

         let formHasError = false;
         if (errorContainingTabNames.some(item => {
            return (item === PATIENT_TAB_NAME) || (item === TREATMENT_TAB_NAME) || (item === REFERRAL_TAB_NAME)
         })) {
            formHasError = true;
         }

         if (fullFormData && !formHasError) {
            setIsSubmitClicked(false);
         }
         if (formHasError) {
            setIsSubmitClicked(false);
            setShowConfirmSubmit(false);
            setIsFormHavingError(formHasError)
            focusErrorField();
         }
         else {
            setIsModalOpen(true);
            setShowConfirmSubmit(true);
         }
      }
   }, [
      isSubmitClicked,
      triggerPatientSubmitAction,
      triggerInsuranceSubmitAction,
      triggerTreatmentSubmitAction,
      triggerAttachmentSubmitAction,
      triggerReferralSubmitAction,
      fullFormData,
      errorContainingTabNames
   ])

   useEffect(() => {
      if (!isModalOpen) {
         if (showSuccessMsg) setShowSuccessMsg(false)
         if (showConfirmSubmit) setShowConfirmSubmit(false)
      }
   }, [isModalOpen])

  const focusErrorField = () => {
    if (getElement('providerEntity').value == '') {
      scrollToError('providerEntity')
    } else if (getElement('phone').value == '') {
      scrollToError('phone')
    } else if (getElement('senderFirstName').value == '') {
      scrollToError('senderFirstName')
    } else if (getElement('senderLastName').value == '') {
      scrollToError('senderLastName')
    } else if (getElement('firstName').value == '') {
      scrollToError('firstName')
    } else if (getElement('lastName').value == '') {
      scrollToError('lastName')
    } else if (getElement('birthDate').value == '') {
      scrollToError('birthDate')
    }
    else if (fullFormData.treatment.preferredTreatmentCenter1 == '') {
      scrollToError('preferredTreatmentCenter1')
    }
  }

   const scrollToError = (selector) => {
      const errorElement: any = document.getElementsByName(selector)[0];
      errorElement.focus();
   }

   const getElement = (selector) => {
      const errorElement: any = document.getElementsByName(selector)[0];
      return errorElement;
   }

   const isPatientTabHavingError = errorContainingTabNames.some(item => item === PATIENT_TAB_NAME)
   const isTreatmentTabHavingError = errorContainingTabNames.some(item => item === TREATMENT_TAB_NAME)
   const isReferralTabHavingError = errorContainingTabNames.some(item => item === REFERRAL_TAB_NAME)

   const sendPatientDataToParent = (data: PatientFormTypes, isHavingError?: boolean) => {

      if (isHavingError) {
         setErrorContainingTabNames(state => Array.from(new Set([...state, PATIENT_TAB_NAME])))
      } else {
         setErrorContainingTabNames(state => state.filter(item => item !== PATIENT_TAB_NAME))

         /** success of patient, open next form */
         setFullFormData(state => ({
            ...state,
            patient: {
               ...state.patient,
               ...data
            }
         }))
      }
      toggleTriggerPatientSubmitAction(false)
   }

   const sendInsuranceDataToParent = (data: InsuranceFormTypes, isHavingError?: boolean) => {

      if (isHavingError) {
         setErrorContainingTabNames(state => Array.from(new Set([...state, INSURANCE_TAB_NAME])))
      } else {
         setErrorContainingTabNames(state => state.filter(item => item !== INSURANCE_TAB_NAME))

         /** success of Insurance, open next form */
         setFullFormData(state => ({
            ...state,
            insurance: {
               ...state.insurance,
               ...data
            }
         }))
      }

      toggleTriggerInsuranceSubmitAction(false)

   }
   const sendTreatmentDataToParent = (data: TreatmentFormTypes, isHavingError?: boolean) => {

      if (isHavingError) {
         setErrorContainingTabNames(state => Array.from(new Set([...state, TREATMENT_TAB_NAME])))
      } else {
         setErrorContainingTabNames(state => state.filter(item => item !== TREATMENT_TAB_NAME))

         /** success of Treatment, open next form */
         setFullFormData(state => ({
            ...state,
            treatment: {
               ...state.treatment,
               ...data
            }
         }))
      }

      toggleTriggerTreatmentSubmitAction(false)

   }
   const sendAttachmentDataToParent = (data: AttachmentFormTypes, isHavingError?: boolean) => {

      if (isHavingError) {
         setErrorContainingTabNames(state => Array.from(new Set([...state, ATTACHMENT_TAB_NAME])))
      } else {
         setErrorContainingTabNames(state => state.filter(item => item !== ATTACHMENT_TAB_NAME))

         /** success of Attachment, open next form */
         setFullFormData(state => ({
            ...state,
            attachment: {
               ...state.attachment,
               ...data
            }
         }))
      }

      toggleTriggerAttachmentSubmitAction(false)

   }
   const sendReferralDataToParent = (data: ReferralFormTypes, isHavingError?: boolean) => {
      if (isHavingError) {
         setErrorContainingTabNames(state => Array.from(new Set([...state, REFERRAL_TAB_NAME])))
      } else {
         setErrorContainingTabNames(state => state.filter(item => item !== REFERRAL_TAB_NAME))

         /** success of Referral, open next form */
         setFullFormData(state => ({
            ...state,
            referral: {
               ...state.referral,
               ...data
            }
         }))
      }
      toggleTriggerReferralSubmitAction(false)
   }

   const createPayloadAndSubmit = (formData: FullFormProps) => {
      const request: submitPayload = {
         patient: {
            birthDate: formData.patient.birthDate,
            city: formData.patient.address.city,
            ethnicity: formData.patient.ethnicityId,
            firstName: formData.patient.firstName,
            genderId: formData.patient.genderId,
            lastName: formData.patient.lastName,
            line1: formData.patient.address.line1,
            line2: formData.patient.address.line2,
            middleName: formData.patient.middleName,
            postalCode: formData.patient.address.postalCode,
            raceId: formData.patient.raceId,
            state: formData.patient.address.state,
            email: formData.patient.email,
            type: 'email'
         },
         insurance: {
            effectiveDate: formData.insurance.effectiveDate ? new Date(formData.insurance.effectiveDate).toDateString() : '',
            insuranceName: formData.insurance.insuranceName,
            insuranceId: formData.insurance.insuranceId
         },
         treatment: {
            isPatientNew: formData.treatment.isPatientNew,
            dialysisFirstDay: formData.treatment.dialysisFirstDay,
            accessType: formData.treatment.accessType,
            modality: formData.treatment.modality,
            preferredTreatmentDays: formData.treatment.preferredTreatmentDays.map((item) => item.label),
            preferredTreatmentTime: formData.treatment.preferredTreatmentTime.map((item) => item.label),
            preferredTreatmentCenter1: formData.treatment.preferredTreatmentCenter1,
            preferredTreatmentCenter2: formData.treatment.preferredTreatmentCenter2,
            preferredTreatmentCenter3: formData.treatment.preferredTreatmentCenter3,
            reasonForDialysis: formData.treatment.reasonForDialysis
         },
         attachment: {
            faceSheet: [],
            demographicsSheet: [],
            hepB: [],
            hAndP: [],
            labWorkup: [],
            dischargeReport: [],
            other: []
         },
         referral: {
            providerEntity: formData.referral.providerEntity,
            nephrologist: formData.referral.nephrologist,
            phone: formData.referral.phone,
            senderFirstName: formData.referral.senderFirstName,
            senderLastName: formData.referral.senderLastName,
            senderTitle: formData.referral.senderTitle
         }
      }
      const formContent = new FormData();

      Object.keys(fullFormData.attachment).map((item: string, index) => {
         fullFormData.attachment[item].forEach(fileRecord => {
            formContent.append(`${item}-file-${index}`, fileRecord);
            formContent.append('fileName', fileRecord.name);
            formContent.append('fileType', item);
         })
      })

      formContent.append('request', JSON.stringify(request));
      setLoading(true)
      postMethodWithUploadURL(postPatientReferralApi, formContent)
         .then(res => {
            const { status } = res
            if (status === 200 || status === 204) {
               setIsModalOpen(true);
               setShowSuccessMsg(true);
               setFullFormData(initialData);
               resetForm();
            } else {
               setHasSubmitApiFailed(res.data.message ? res.data.message : 'An error has occured')
            }
            setLoading(false)
         })
         .catch(err => {
            setLoading(false)
            setHasSubmitApiFailed(err?.response?.data?.message ?? 'An error has occured')
            console.log('err: ', err)
         })
   }

   const confirmSubmit = () => {
      setIsSubmitClicked(true);
      toggleTriggerPatientSubmitAction(true);
      toggleTriggerInsuranceSubmitAction(true);
      toggleTriggerTreatmentSubmitAction(true);
      toggleTriggerAttachmentSubmitAction(true);
      toggleTriggerReferralSubmitAction(true);
      if (!switchGoogleCaptcha) {
         const element: any = document.querySelector('#clientId');
         if (element.value != '') {
            setHasSubmitApiFailed('An error has occurred')
            return;
         }
      }
      setReCaptchaValue('');
   }

   const handleSubmit = e => {
      e.preventDefault()
      if ((switchGoogleCaptcha ? ReCaptchaValue == '' : false)) {
         setHasSubmitApiFailed('An error has occurred')
         return;
      }
      createPayloadAndSubmit(fullFormData);
      setShowConfirmSubmit(false);
      setIsModalOpen(false);
   }

   const clearForm = () => {
      setShowSuccessMsg(false);
      setShowConfirmSubmit(false);
      setIsModalOpen(false);
   }

   const handleReCaptchaChange = value => {
      setReCaptchaValue(value);
   };

   const resetForm = () => {
      toggleTriggerPatientResetForm(!triggerPatientResetForm);
      toggleTriggerInsuranceResetForm(!triggerInsuranceResetForm);
      toggleTriggerAttachmentResetForm(!triggerAttachmentResetForm);
      toggleTriggerReferralResetForm(!triggerReferralResetForm);
      toggleTriggerTreatmentResetForm(!triggerTreatmentResetForm);
      Array.from(document.querySelectorAll('textarea')).forEach((input) => (input.value = ''))
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''))
   }

   const expiredCallback = () => { };

   return (
      <>
         <div className="app-header">
            <div
               style={{
                  flex: '0 0 auto',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px'
               }}
            >
               <a href="https://www.satellitehealthcare.com/" target="_blank" rel="noreferrer">
                  <img width="140" src="./assets/Images/SH-Stack_Royal_3535U_2022.png" alt=''
                     style={{
                        maxWidth: '12.5rem',
                        width: '100%',
                     }}
                  />
               </a>
            </div>
            <div
               style={{
                  flex: '1',
                  marginLeft: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#604475'
               }}
            >
               <h4>Patient Referral Portal</h4>
            </div>
            <div style={{
               fontSize: '12px',
               display: 'flex',
               flexDirection: 'row',
               marginRight: '10px',
               color: '#604475'
            }}>
               <div style={{ marginRight: '10px', fontSize: '10px' }}>
                  <div style={{
                     fontWeight: '600'
                  }}>
                     New Patient Placement/Admissions
                  </div>
                  <div>
                     <span style={{ fontWeight: '600' }}>Phone:</span> 855.396.2212
                  </div>
                  <div>
                     <span style={{ fontWeight: '600' }}>Fax:</span> 650.779.9150
                  </div>
                  <div>
                     <span style={{ fontWeight: '600' }}>Email:</span> patientadmissions@satellitehealth.com
                  </div>
               </div>
               <div style={{ fontSize: '10px' }}>
                  <div style={{ fontWeight: '600' }}>
                     DCRN Requests
                  </div>
                  <div>
                     <span style={{ fontWeight: '600' }}>Phone:</span> 800.367.8292
                  </div>
               </div>
            </div>
         </div>
         {loading ? <div className="loading"></div> : <></>}
         <div className="app-content mt-3"  style={{height:'90VH', overflowY:'scroll'}}>
            <div className="container">
               <div className="card main-card mt-0">
                  <ErrorTooltip isVisible={isReferralTabHavingError} title={'Please fill all required details'} />
                  <ReferralDetails
                     formData={fullFormData.referral}
                     triggerSubmitAction={triggerReferralSubmitAction}
                     triggerResetForm={triggerReferralResetForm}
                     sendDataToParent={sendReferralDataToParent}
                  />
                  <PatientDetails
                     formData={fullFormData.patient}
                     triggerSubmitAction={triggerPatientSubmitAction}
                     triggerResetForm={triggerPatientResetForm}
                     sendDataToParent={sendPatientDataToParent}
                  />
                  <InsuranceDetails
                     formData={fullFormData.insurance}
                     triggerSubmitAction={triggerInsuranceSubmitAction}
                     triggerResetForm={triggerInsuranceResetForm}
                     sendDataToParent={sendInsuranceDataToParent}
                  />
                  <TreatmentDetails
                     formData={fullFormData.treatment}
                     triggerSubmitAction={triggerTreatmentSubmitAction}
                     triggerResetForm={triggerTreatmentResetForm}
                     sendDataToParent={sendTreatmentDataToParent}
                  />
                  <Attachments
                     formData={fullFormData.attachment}
                     triggerSubmitAction={triggerAttachmentSubmitAction}
                     triggerResetForm={triggerAttachmentResetForm}
                     sendDataToParent={sendAttachmentDataToParent}
                  />
               </div>
               <div className="main-button-block pt-3 pb-5">
                  <button className="btn cancel-btn mr-4">Clear</button>
                  <button className="btn save-btn btn-primary" type="submit" onClick={confirmSubmit}>
                     Submit
                  </button>
               </div>
            </div>
         </div>
         <Modal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleModalClose={() => {
               setIsModalOpen(false)
            }}
         >
            {showSuccessMsg && (
               <div className="app-referral-modal">
                  <div className="modal-body py-4 my-1">
                     <img width="64" src="./assets/Images/icon-success.png" alt="Close" />
                     <p>
                        Your <span>Referral Details</span> has been
                        <br /> submitted successfully!
                     </p>
                     <button className="btn btn-success" onClick={clearForm}>
                        OK
                     </button>
                  </div>
               </div>
            )}
            {showConfirmSubmit &&  (
               <div className="app-referral-modal">
                  <div className="modal-body p-0">
                     <img width="64" src="./assets/Images/icon-confirm.png" alt="Close" />
                     <p>Are you sure you want to submit the details?</p>
                     {switchGoogleCaptcha ? (
                        <ReCAPTCHA
                           style={{ display: 'inline-block' }}
                           data-theme="light"
                           callback={handleReCaptchaChange}
                           expiredCallback={expiredCallback}
                           sitekey={GoogleReCaptchaV2}
                        />
                     ) : (
                        <></>
                     )}
                     <div className="button-block">
                        <button className="btn btn-confirm btn-default" onClick={() => setIsModalOpen(false)}>
                           No
                        </button>
                        <button className="btn btn-confirm btn-primary" onClick={(e) => handleSubmit(e)}>
                           Yes
                        </button>
                     </div>
                  </div>
               </div>
            )}
         </Modal>
         {isFormHavingError && (
            <SnackbarComponent
               isOpen={isFormHavingError}
               setIsOpen={(flag) => {
                  setIsFormHavingError(flag)
               }}
               message={`Please check ${isReferralTabHavingError 
                  ? 'Referral Source Details'
                  : isPatientTabHavingError
                     ? 'Patient Details'
                     : isTreatmentTabHavingError
                        ? 'Dialysis Treatment Details'
                        : ''
                  } section for errors`}
               severity={'warning'}
            />
         )}        
         {hasSubmitApiFailed && (
            <SnackbarComponent
               isOpen={Boolean(hasSubmitApiFailed)}
               setIsOpen={(flag) => {
                  setHasSubmitApiFailed('')
               }}
               message={hasSubmitApiFailed}
               severity={'error'}
            />
         )}
         {uploadSnackBar.message && (
            <SnackbarComponent
               isOpen={Boolean(uploadSnackBar.message)}
               setIsOpen={(flag) => {
                  setUploadSnackbar({ message: '', severity: 'success' })
               }}
               message={uploadSnackBar.message}
               severity={uploadSnackBar.severity}
            />
         )}
      </>
   )
}

export default ReferralForm;
