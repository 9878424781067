/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root':{
    maxWidth: 1000,
    borderRadius: 10
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

interface ModalProps {
  isModalOpen: boolean
  setIsModalOpen: Function
  isCloseButtonHidden?: boolean
  children?: React.ReactNode
  handleModalClose?: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <img src="./assets/Images/close.png" alt="Close" className='close-img'/>
        </IconButton>
      }
    </DialogTitle>
  )
}

export default function Modal({ isModalOpen, setIsModalOpen, isCloseButtonHidden, children, handleModalClose }: ModalProps) {
  const handleClose = () => {
    
    if(handleModalClose) {
      handleModalClose()
    } else {
      setIsModalOpen(false)
    }
  }

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        {!(isCloseButtonHidden ?? false) && (
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  )
}
