import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// eslint-disable-next-line react/prop-types
export default function BasicSelect({ name, value, handleChange, options, label = "",
                                      defaultOption, isDisabled = false, isRequired = false,
                                      errorMessage = "", showLabelSection = true, disableFirstOption = false }) {

   const onChangeType = (e) => {
      handleChange(e);
   };
   return (
      <div className="form-group">
         {showLabelSection &&
            <label className="d-flex justify-content-between">
               <div className="d-flex">
                  {label} <span className="required">{isRequired && '*'}</span>
               </div>
               {(errorMessage.length > 0) && <span style={{ color: '#dc3545' }}>{errorMessage}</span>}
            </label>
         }
         <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
               {(value === null || value === '') && <InputLabel id="demo-simple-select-label">{defaultOption}</InputLabel>}
               <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  onChange={onChangeType}
                  name={name}
                  label={label}
                  disabled={isDisabled}
               >
                  <MenuItem value="" key="" disabled={disableFirstOption}>Select {label}</MenuItem>
                  {options?.map((type) => (
                     <MenuItem value={type.id} key={type.id}>
                        {type.value}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Box>
      </div>
   );
}