import { useState, forwardRef, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


interface SnackbarProps {
    isOpen: boolean
    setIsOpen: (flag: boolean) => void
    message: string
    severity: 'success' | 'info' | 'warning' | 'error'
}

export default function SnackbarComponent({
    isOpen, setIsOpen, message, severity
}: SnackbarProps) {

    const [state, setState] = useState<SnackbarOrigin>({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const handleClose = () => {
        setIsOpen(false)
    };

    useEffect(() => {
        /** To make auto-hide work, revert the flag after 4 sec */
        if (isOpen) {
            setTimeout(() => {
                handleClose()
            }, 4000)
        }
    }, [])


    return (
        <Stack spacing={2} sx={{ width: '100%' }} >
            <Snackbar
                open={isOpen}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}