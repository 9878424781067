import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import BasicSelect from "../../../component/common/BasicSelect";
import InputBox from "../../../component/common/InputBox";
import { TreatmentDetailsProps } from "../../../types/formTypes";
import { AccessTypeDropdownValues, ModalityDropdownValues, strip, TreatmentTimeValues, TreatmentDaysValues, IsPatientNewToDialysis } from "../../../utils/utilities";
import Autosuggested from "../../../component/common/Autocomplete";
import Checkbox from "../../../component/common/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const d = new Date();
const maxDate = new Date(d.getFullYear() - 10, d.getMonth(), d.getDate());

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const TreatmentDetails = ({
   formData,
   triggerSubmitAction,
   triggerResetForm,
   sendDataToParent   
}: TreatmentDetailsProps) => {

   const [isSubmitClicked, setIsSubmitClicked] = useState(false)
   const [selectedTreatmentDays, setSelectedTreatmentDays] = useState<any[]>([])
   const [selectedTreatmentTimings, setselectedTreatmentTimings] = useState<any[]>([])

   const formik = useFormik({
      initialValues: {
         ...formData
      },
      onSubmit: (values, { setErrors }) => {
         // console.log('hex form')
      },
   });

   useEffect(() => {
     setIsSubmitClicked(false)
     formik.resetForm();
     setSelectedTreatmentDays([]);
     setselectedTreatmentTimings([]);
   }, [triggerResetForm])

   const validationSuccess = () => {
      return formik.values.preferredTreatmentCenter1 !== ''
   }

   useEffect(() => {
      if (triggerSubmitAction) {
         setIsSubmitClicked(true)
         sendDataToParent(formik.values, !validationSuccess())
      }
   }, [triggerSubmitAction])

   const treatmentDetailsForm = [
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Placement Type',
         name: 'isPatientNew',
         value: formik.values.isPatientNew,
         required: false,
         options: IsPatientNewToDialysis
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'date',
         label: 'First Day of Dialysis',
         name: 'dialysisFirstDay',
         placeHolder: 'MM-DD-YYYY',
         value: formik.values.dialysisFirstDay,
         required: false,
         disabled: (formik.values.isPatientNew === 'Yes')
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Access Type',
         name: 'accessType',
         value: formik.values.accessType,
         required: false,
         options: AccessTypeDropdownValues
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Modality',
         name: 'modality',
         value: formik.values.modality,
         required: false,
         options: ModalityDropdownValues
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'autosuggested',
         checkboxVersion: false,
         label: 'Preferred Treatment Day(s)',
         name: 'preferredTreatmentDays',
         value: formik.values.preferredTreatmentDays,
         required: false,
         options: TreatmentDaysValues,
         selectedOptions: selectedTreatmentDays,
         setSelectedOptions: setSelectedTreatmentDays
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'autosuggested',
         checkboxVersion: false,
         label: 'Preferred Treatment Time',
         name: 'preferredTreatmentTime',
         value: formik.values.preferredTreatmentTime,
         required: false,
         options: TreatmentTimeValues,
         selectedOptions: selectedTreatmentTimings,
         setSelectedOptions: setselectedTreatmentTimings
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Preferred Location 1',
         name: 'preferredTreatmentCenter1',
         placeholder:'Please Enter Preferred Location 1',
         value: formik.values.preferredTreatmentCenter1,
         required: true,
         errorMessage: isSubmitClicked && formik.values.preferredTreatmentCenter1 === '' ? 'required' : ''
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Preferred Location 2',
         name: 'preferredTreatmentCenter2',
         placeholder:'Please Enter Preferred Location 2',
         value: formik.values.preferredTreatmentCenter2,
         required: false
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Preferred Location 3',
         name: 'preferredTreatmentCenter3',
         placeholder:'Please Enter Preferred Location 3',
         value: formik.values.preferredTreatmentCenter3,
         required: false
      },
      {
         class: 'col-12 col-md-12',
         elementType: 'textarea',
         label: 'Please Enter Reason for Dialysis...',
         rows: 5,
         name: 'reasonForDialysis',
         placeHolder: 'MM-DD-YYYY',
         value: formik.values.reasonForDialysis,
         required: false,
         disabled: (formik.values.isPatientNew === 'Yes')
      },
   ]

   const handlePatientDetailsChange = (e, formikObjectPassed) => {
      const { name, value } = e.target

      // formikObjectPassed.setFieldValue("dialysisFirstDay",
      //   name === 'isPatientNew' && value === "Yes" ?
      //      "" :
      //      moment().format('YYYY-MM-DD')
      // )

      formikObjectPassed.setFieldValue(name, value)
   }

   return (
     <>
       <div id="dialysis" className="tabcontent pb-0">
         <div className="title">
           <p>Dialysis Treatment Details</p>
         </div>
         <div>
           <form className="row">
             {treatmentDetailsForm.map((field, index) => (
               <div className={field.class} key={index}>
                 {field.elementType === 'input' ? (
                   field.name === 'dialysisFirstDay' && typeof field.value === 'string' ? (
                     <div className="form-group">
                       <label className="d-flex justify-content-between">
                         <div className="d-flex">
                           {field.label} <span className="required">{field.required && '*'}</span>
                         </div>
                       </label>
                       <input
                         type="date"
                         className="form-control"
                         name={field.name}
                         value={field.value}
                         onChange={(e) => handlePatientDetailsChange(e, formik)}
                         placeholder={field.label}
                         max={moment(maxDate).format('YYYY-MM-DD')}
                         disabled={field.disabled}
                         // min={moment(maxDate).format('YYYY-MM-DD')}
                       />
                     </div>
                   ) : (
                     <InputBox
                       type={field.type}
                       label={field.label}
                       name={field.name}
                       value={field.value}
                       placeholder={field.placeholder}
                       handleChange={(e) => handlePatientDetailsChange(e, formik)}
                       required={field.required}
                       errorMessage={field.errorMessage}
                     />
                   )
                 ) : (
                   <div className="form-group">
                     {field.elementType !== 'textarea' && (
                       <label className="d-flex justify-content-between">
                         <div className="d-flex">
                           {field.label} <span className="required">{field.required && '*'}</span>
                         </div>
                       </label>
                     )}
                     {field.elementType === 'select' ? (
                       <BasicSelect
                         name={field.name}
                         label={field.label}
                         value={field.value}
                         defaultOption={field.label}
                         options={field.options}
                         handleChange={(e) => handlePatientDetailsChange(e, formik)}
                         showLabelSection={false}
                       />
                     ) : field.elementType === 'autosuggested' && field.options && field.selectedOptions ? (
                       <>
                         <div className="input-group">
                           <Autosuggested
                             disableCloseOnSelect={true}
                             placeholder={field.label}
                             renderOption={(props, option, { selected }) => {
                               const allSelected = field.options.length === field.selectedOptions.length
                               const selectAllProps =
                                 option.code === 'All' // To control the state of 'select-all' checkbox
                                   ? { checked: allSelected }
                                   : {}
                               return (
                                 <li
                                   {...props}
                                   style={{
                                     cursor: field.selectedOptions.filter((item) => item.id === option.id)[0]
                                       ? 'not-allowed'
                                       : 'pointer',
                                     pointerEvents: field.selectedOptions.filter((item) => item.id === option.id)[0]
                                       ? 'none'
                                       : 'all'
                                   }}
                                   onClick={(e) => {
                                     field.setSelectedOptions([...field.selectedOptions, option])
                                     handlePatientDetailsChange(
                                       { target: { name: field.name, value: [...field.selectedOptions, option] } },
                                       formik
                                     )
                                   }}
                                 >
                                   {field.checkboxVersion ? (
                                     <Checkbox
                                       icon={icon}
                                       checkedIcon={checkedIcon}
                                       checked={selected}
                                       style={{ marginRight: 8 }}
                                       {...selectAllProps}
                                     />
                                   ) : (
                                     <></>
                                   )}
                                   {option.label}
                                 </li>
                               )
                             }}
                             value={field.selectedOptions.map(
                               (item: { id: string; label: string; value: string }) => item.label
                             )}
                             onDelete={(option) =>
                               field.setSelectedOptions(field.selectedOptions.filter((obj) => obj.label !== option))
                             }
                             onClear={() => field.setSelectedOptions([])}
                             getOptionLabel={(option) => option.label}
                             multiple={true}
                             data={field.options}
                             textFieldLabel=""
                           />
                         </div>
                       </>
                     ) : field.elementType === 'textarea' ? (
                       <>
                         <div className="form-group col-md-8 pl-0 pr-2">
                           <label htmlFor="exampleInputPassword1">{field.label}</label>
                           <textarea
                             className={field.class}
                             rows={field.rows}
                             name={field.name}
                             placeholder={field.label}
                             onChange={(e) => handlePatientDetailsChange(e, formik)}
                           ></textarea>
                         </div>
                       </>
                     ) : (
                       <>
                         {/* <Autocomplete
                                                                value={[field.value]}
                                                                multiple={field.multiple}
                                                                data={AccessTypeDropdownValues}
                                                                getOptionLabel={(option) => option?.label}
                                                                renderTags={(value: readonly string[], getTagProps) =>
                                                                    value.map((option: string, index: number) => (
                                                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                                    ))
                                                                }
                                                                onChange={(e, x) => handleSelectParent(e, field.name, x, formik)}
                                                            /> */}
                       </>
                     )}
                   </div>
                 )}
               </div>
             ))}
             <input
               id="clientId"
               name="clientId"
               type="text"
               className="pt-2"
               value={formik.values.clientId}
               onChange={(e) => handlePatientDetailsChange(e, formik)}
             />
           </form>
         </div>
       </div>
     </>
   )
}

export default TreatmentDetails;