/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState, useEffect, useCallback, memo } from "react";
import { useDropzone } from "react-dropzone";
import { AppStateContext } from "../../../App";
import { deleteMethodWithNewURL, getDataWithNewURL, postMethodWithUploadURL } from "../../../services/http";
import { convertDate, convertDateTime, downloadDocument, uploadMaxSize } from "../../../utils/utilities";
import Loader from '../Loader'

interface AttachmentComponentProps {
   showUploadSection?: boolean
   isNewInventory?: boolean
   filesData: any[]
   setAppState: any,
   maxSize?: number,
   maxFileCount?: number,
   updateCategory?: boolean,
   attachmentCategory?: any[],
   setUpdateCategory?: any
   supportedFormats?: any
}

const Attachments = ({
   showUploadSection = true,
   isNewInventory,
   filesData,
   setAppState,
   maxSize = uploadMaxSize, // max size is uesd for file size (mb or kb) 
   maxFileCount = 1,
   attachmentCategory,
   setUpdateCategory,
   supportedFormats
}: AttachmentComponentProps) => {

   const [isLoading, setIsLoading] = useState(false) // setup Loader feature

   const readLocalFiles = useCallback((acceptedFiles_) => {

      const staticUploadedFiles = acceptedFiles_.map(file => Object.assign(file, {
         preview: URL.createObjectURL(file)
      }))

      if (staticUploadedFiles.length > 0) {
         setAppState(
            Array.isArray(filesData) && filesData.length > 0 ?
               [...filesData, ...staticUploadedFiles] : staticUploadedFiles
         )
      }

   }, [filesData])


   const onDrop = ((acceptedFiles: any, rejectedFiles: any) => {
      if (acceptedFiles?.length > 0) {

         if (isNewInventory) {
            readLocalFiles(acceptedFiles)
         }
      }
      if (rejectedFiles?.length > 0) {
         setAppState((s) => ({
            ...s,
            showSnackBar: true,
            snackBarMsg: 'File Size is more than 5 mb',
            snackMsgType: 'error'
         }))
      }
   })

   const removeFiles = (fileData, index) => {
      if (isNewInventory) {
         try {
            const newFilesData = filesData.filter((file, inx) => index !== inx)

            setAppState(newFilesData)
         } catch (e) {
            console.log('Error in deleting file: ', e)
         }
      }
   }


   const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
   } = useDropzone({
      onDrop,
      accept: {
         'application/pdf': ['.pdf'],
         'text/csv': ['.csv'],
         'text/plain': ['.txt'],
         'image/jpeg': [],
         'image/png': [],
         'image/tiff': []
      },
      maxSize: maxSize,
      maxFiles: maxFileCount
   });

   if (isLoading) return <Loader />

   return <div className="" id="attach-cmpt">
      {
         showUploadSection &&
         <div className="tab-pane position-relative " id="2" {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
            <input type="file" name="images" id="images" multiple {...getInputProps()} />
            <div className="file-dummy">
               {false && <div className="success">Great, your files are selected. Keep on.</div>}
               <div className="default"><i className="fa fa-paperclip mr-2"></i>Attach Documents to Upload</div>
            </div>
         </div>
      }

      <div className="pt-3">
         <div className="d-block d-md-flex align-items-center justify-content-between docs-list">
            {
               // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
               filesData?.map((fileValue: any, index: number) => (
                  <>
                     <div className="col-md-6 px-0" key={index}>
                        <div className="d-flex file align-items-center mb-2 justify-content-between">
                           <span>
                              <i className="fa fa-file-text mr-1" aria-hidden="true"></i>
                              {fileValue.name}
                           </span>
                           <button className="btn-app-btn no-border" onClick={() => removeFiles(fileValue, index)}>
                              <i className="fa fa-times"></i>
                           </button>
                        </div>
                     </div>
                  </>
               ))
            }
         </div>
         <label className="form-group label mb-0">Supported documents: .pdf, .csv, .txt, .jpeg, .tiff, .png</label>
      </div>

   </div>
}
export default Attachments;