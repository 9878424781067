import { useFormik } from "formik";
import { useEffect, useState } from "react";
import BasicSelect from "../../../component/common/BasicSelect";
import InputBox from "../../../component/common/InputBox";
import { ReferralDetailsProps } from "../../../types/formTypes";
import { checkIsNumber, maskPhoneNumbertoUS } from "../../../utils/form";
import { SenderTitleDropdownValues } from "../../../utils/utilities";

const ReferralDetails = ({
   formData,
   triggerSubmitAction,
   triggerResetForm,
   sendDataToParent
}: ReferralDetailsProps) => {

   const [isSubmitClicked, setIsSubmitClicked] = useState(false)


   const formik = useFormik({
      initialValues: {
         ...formData
      },
      onSubmit: (values, { setErrors }) => {
         // console.log('hex form')
      },
   });

   const validationSuccess = () => {
      return (
         (formik.values.providerEntity !== '') &&
         (formik.values.phone !== '') &&
         (formik.values.senderFirstName !== '') &&
         (formik.values.senderLastName !== '') &&
         (referralDetailsForm.every(item => !item.errorMessage))
      )
   }

   useEffect(() => {
      setIsSubmitClicked(false)
      formik.resetForm()
   }, [triggerResetForm])

   useEffect(() => {
      if (triggerSubmitAction) {
         setIsSubmitClicked(true)
         sendDataToParent(formik.values, !validationSuccess())
      }
   }, [triggerSubmitAction])

   const referralDetailsForm = [
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Provider Entity',
         name: 'providerEntity',
         value: formik.values.providerEntity,
         required: true,
         errorMessage: isSubmitClicked && formik.values.providerEntity === '' ? 'required' : '',
         placeholder: 'Please Enter Provider Entity...'
      },
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Nephrologist/ Physician',
         name: 'nephrologist',
         value: formik.values.nephrologist,
         required: false,
         errorMessage: '',
         placeholder: 'Please Enter Nephrologist/Physician...'
      },
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'tel',
         label: 'Enter Phone Number',
         placeholder: '+1 (123) 456- 7899',
         name: 'phone',
         value: formik.values.phone,
         required: true,
         errorMessage: isSubmitClicked && formik.values.phone === '' ? 'required' : ''
      },
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Sender First Name',
         name: 'senderFirstName',
         value: formik.values.senderFirstName,
         required: true,
         errorMessage: isSubmitClicked && formik.values.senderFirstName === '' ? 'required' : '',
         placeholder: 'Please Enter Sender First Name...'
      },
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Sender Last Name',
         name: 'senderLastName',
         value: formik.values.senderLastName,
         required: true,
         errorMessage: isSubmitClicked && formik.values.senderLastName === '' ? 'required' : '',
         placeholder: 'Please Enter Sender Last Name...'
      },
      {
         class: 'col-md-4',
         elementType: 'select',
         label: 'Sender Title',
         name: 'senderTitle',
         placeHolder: 'Please Select Sender Title...',
         value: formik.values.senderTitle,
         options: SenderTitleDropdownValues,
         required: false
      },
   ]

   const handleReferralDetailsChange = (e, formikObjectPassed) => {
      const { name, value } = e.target
      formikObjectPassed.setFieldValue(name, value)
   }

   const handleTelecomChange = (e, fieldObject, formik) => {
      const { name, value } = e.target
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (checkIsNumber(value)) {

         handleReferralDetailsChange({
            target: {
               name: name,
               value: maskPhoneNumbertoUS(e)
            }
         }, formik)
      }

   }

   return (
      <>
         <div id="referral" className="tabcontent pb-0">
            <div className="title">
               <p>Referral Source Details</p>
            </div>
            <div>
               <form className="row">
                  {referralDetailsForm.map((field, index) => (
                     <div className={field.class} key={index}>
                        {field.type == 'tel' ? (
                           <InputBox
                              type={field.type}
                              label={field.label}
                              name={field.name}
                              value={field.value}
                              handleChange={(e) => handleTelecomChange(e, field, formik)}
                              placeholder={field.placeholder}
                              required={field.required}
                              errorMessage={field.errorMessage}
                           />
                        ) : (
                           field.elementType === 'input' ? (<InputBox
                              type={field.type}
                              label={field.label}
                              name={field.name}
                              value={field.value}
                              handleChange={(e) => handleReferralDetailsChange(e, formik)}
                              required={field.required}
                              placeholder={field.placeholder}
                              errorMessage={field.errorMessage}
                           />
                        ):(field.elementType === 'select' ? (
                           <BasicSelect
                              name={field.name}
                              label={field.label}
                              value={field.value}
                              defaultOption={field.placeHolder}
                              options={field.options}
                              handleChange={(e) => {
                                 handleReferralDetailsChange(e, formik)
                              }}
                           />
                        ) : (
                           <></>
                        )))}
                     </div>
                  ))}
               </form>
            </div>
         </div>
      </>
   )
}

export default ReferralDetails;