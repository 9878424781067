// Covert datetime
// If toUTC is true then return UTC time other wise return local time
import moment from 'moment'
import { getDataWithDocument } from '../services/http.js'

export function convertLocalDateToUTCDate(date, toUTC) {
   date = new Date(date)
   const localOffset = date.getTimezoneOffset() * 60000
   const localTime = date.getTime()
   if (toUTC) {
      date = localTime + localOffset
   } else {
      date = localTime - localOffset
   }
   date = new Date(date)
   return date
}

export function convertDateTime(dateValue) {
   return dateValue ? moment(new Date(dateValue)).format('MM-DD-YYYY HH:mm A') : ''
}

export function convertDate(dateValue) {
   return dateValue ? moment(new Date(dateValue)).format('MM-DD-YYYY') : ''
}


export const referralSourceDetailstabsName = [{ name: 'Contacts' }, { name: 'Links' }, { name: 'Work Items' }, { name: 'Notes' }]

export const strip = (string_, substitutes) => {
   return substitutes.reduce((accumulator, substitute) => {
      return accumulator.replace(substitute.search, substitute.replace);
   }, string_);
}


export const patientTabs = [
   { name: 'General' },
   { name: 'Medical' },
   { name: 'Financial/Insurance' },
   { name: 'AKF' },
   { name: 'Contacts' },
   { name: 'Work Items' },
   { name: 'Attachments' },
   { name: 'Notes' },
]

export const WorkItemTabsViewMode = [
   { name: 'Attachments' },
   { name: 'Notes' },
   { name: 'Links' },
]
export const WorkItemTabsEditMode = [
   { name: 'Attachments' },
   { name: 'Links' },
]
export const WorkItemTypeTabs = [
   { name: 'Details' },
   { name: 'Setting' },
]

export const GenderDropdownValues = [
   { id: 'Male', label: 'Male', value: 'Male' },
   { id: 'Female', label: 'Female', value: 'Female' },
]

export const YesNoDropdownValues = [
   { id: 'Yes', label: 'Yes', value: 'Yes' },
   { id: 'No', label: 'No', value: 'No' },
]

export const PreferredTimeValues = [
   { id: '1', label: '1', value: '1' },
   { id: '2', label: '2', value: '2' },
   { id: '3', label: '3', value: '3' },
   { id: '4', label: '4', value: '4' },
]

export const PreferredLocationValues = [
   { id: 'Location 1', label: 'Location 1', value: 'Location 1' },
   { id: 'Location 2', label: 'Location 2', value: 'Location 2' },
   { id: 'Location 3', label: 'Location 3', value: 'Location 3' },
   { id: 'Location 4', label: 'Location 4', value: 'Location 4' },
]

export const AccessTypeDropdownValues = [
   { id: 'AV Fistula', label:'AV Fistula', value: 'AV Fistula' },
   { id: 'AV Graft', label:'AV Graft', value: 'AV Graft' },
   { id: 'CV Catheter', label:'CV Catheter', value: 'CV Catheter' },
   { id: 'PD Catheter', label:'PD Catheter', value: 'PD Catheter' },
   { id: 'No access', label:'No access', value: 'No access' }
]

export const WeekDaysDropdownValues = [
   { id: '1', label: 'Sunday', value: 'Sunday' },
   { id: '2', label: 'Monday', value: 'Monday' },
   { id: '3', label: 'Tuesday', value: 'Tuesday' },
   { id: '4', label: 'Wednesday', value: 'Wednesday' },
   { id: '5', label: 'Thursday', value: 'Thursday' },
   { id: '6', label: 'Friday', value: 'Friday' },
   { id: '7', label: 'Saturday', value: 'Saturday' }
]

export const TimesDropdownValues = [
   { id: '1', label: '5:00 AM', value: '5:00 AM' },
   { id: '2', label: '5:15 AM', value: '5:15 AM' },
   { id: '3', label: '5:30 AM', value: '5:30 AM' },
   { id: '4', label: '5:45 AM', value: '5:45 AM' },
   { id: '5', label: '6:00 AM', value: '6:00 AM' },
   { id: '6', label: '6:15 AM', value: '6:15 AM' },
   { id: '7', label: '6:30 AM', value: '6:30 AM' },
   { id: '8', label: '6:45 AM', value: '6:45 AM' },
   { id: '9', label: '7:00 AM', value: '7:00 AM' },
   { id: '10', label: '7:15 AM', value: '7:15 AM' },
   { id: '11', label: '7:30 AM', value: '7:30 AM' },
   { id: '12', label: '7:45 AM', value: '7:45 AM' },
   { id: '13', label: '8:00 AM', value: '8:00 AM' },
   { id: '14', label: '8:15 AM', value: '8:15 AM' },
   { id: '15', label: '8:30 AM', value: '8:30 AM' },
   { id: '16', label: '8:45 AM', value: '8:45 AM' },
   { id: '17', label: '9:00 AM', value: '9:00 AM' },
   { id: '18', label: '9:15 AM', value: '9:15 AM' },
   { id: '19', label: '9:30 AM', value: '9:30 AM' },
   { id: '20', label: '9:45 AM', value: '9:45 AM' },
   { id: '21', label: '10:00 AM', value: '10:00 AM' },
   { id: '22', label: '10:15 AM', value: '10:15 AM' },
   { id: '23', label: '10:30 AM', value: '10:30 AM' },
   { id: '24', label: '10:45 AM', value: '10:45 AM' },
   { id: '25', label: '11:00 AM', value: '11:00 AM' },
   { id: '26', label: '11:15 AM', value: '11:15 AM' },
   { id: '27', label: '11:30 AM', value: '11:30 AM' },
   { id: '28', label: '11:45 AM', value: '11:45 AM' },
   { id: '29', label: '12:00 PM', value: '12:00 PM' },
   { id: '30', label: '12:15 PM', value: '12:15 PM' },
   { id: '31', label: '12:30 PM', value: '12:30 PM' },
   { id: '32', label: '12:45 PM', value: '12:45 PM' },
   { id: '33', label: '1:00 PM', value: '1:00 PM' },
   { id: '34', label: '1:15 PM', value: '1:15 PM' },
   { id: '35', label: '1:30 PM', value: '1:30 PM' },
   { id: '36', label: '1:45 PM', value: '1:45 PM' },
   { id: '37', label: '2:00 PM', value: '2:00 PM' },
   { id: '38', label: '2:15 PM', value: '2:15 PM' },
   { id: '39', label: '2:30 PM', value: '2:30 PM' },
   { id: '40', label: '2:45 PM', value: '2:45 PM' },
   { id: '41', label: '3:00 PM', value: '3:00 PM' },
   { id: '42', label: '3:15 PM', value: '3:15 PM' },
   { id: '43', label: '3:30 PM', value: '3:30 PM' },
   { id: '44', label: '3:45 PM', value: '3:45 PM' },
   { id: '45', label: '4:00 PM', value: '4:00 PM' },
   { id: '46', label: '4:15 PM', value: '4:15 PM' },
   { id: '47', label: '4:30 PM', value: '4:30 PM' },
   { id: '48', label: '4:45 PM', value: '4:45 PM' },
   { id: '49', label: '5:00 PM', value: '5:00 PM' },
   { id: '50', label: '5:15 PM', value: '5:15 PM' },
   { id: '51', label: '5:30 PM', value: '5:30 PM' },
   { id: '52', label: '5:45 PM', value: '5:45 PM' },
   { id: '53', label: '6:00 PM', value: '6:00 PM' },
   { id: '54', label: '6:15 PM', value: '6:15 PM' },
   { id: '55', label: '6:30 PM', value: '6:30 PM' },
   { id: '56', label: '6:45 PM', value: '6:45 PM' },
   { id: '57', label: '7:00 PM', value: '7:00 PM' },
   { id: '58', label: '7:15 PM', value: '7:15 PM' },
   { id: '59', label: '7:30 PM', value: '7:30 PM' },
   { id: '60', label: '7:45 PM', value: '7:45 PM' },
   { id: '61', label: '8:00 PM', value: '8:00 PM' },
   { id: '62', label: '8:15 PM', value: '8:15 PM' },
   { id: '63', label: '8:30 PM', value: '8:30 PM' },
   { id: '64', label: '8:45 PM', value: '8:45 PM' },
   { id: '65', label: '9:00 PM', value: '9:00 PM' },
   { id: '66', label: '9:15 PM', value: '9:15 PM' },
   { id: '67', label: '9:30 PM', value: '9:30 PM' },
   { id: '68', label: '9:45 PM', value: '9:45 PM' },
   { id: '69', label: '10:00 PM', value: '10:00 PM' },
   { id: '70', label: '10:15 PM', value: '10:15 PM' },
   { id: '71', label: '10:30 PM', value: '10:30 PM' },
   { id: '72', label: '10:45 PM', value: '10:45 PM' }
]

export const EthnicityDropdownValues = [
   { id: 'Hispanic or Latino', label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
   { id: 'Not Hispanic or Latino', label: 'Not Hispanic or Latino', value: 'Not Hispanic or Latino' },
   { id: 'Declined to specify', label: 'Declined to specify', value: 'Declined to specify' }
]

export const RaceDropdownValues = [
   { id: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native' },
   { id: 'Asian', label: 'Asian', value: 'Asian' },
   { id: 'Black or African American', label: 'Black or African American', value: 'Black or African American' },
   { id: 'Native Hawaiian or Other Pacific Islander', label: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander' },
   { id: 'White', label: 'White', value: 'White' },
   { id: 'Unknown', label: 'Unknown', value: 'Unknown' },
   { id: 'Declined to specify', label: 'Declined to specify', value: 'Declined to specify' }
]

export const ModalityDropdownValues = [
   { id: 'Hemodialysis', label: 'Hemodialysis', value: 'Hemodialysis' },
   { id: 'CAPD', label: 'CAPD', value: 'CAPD' },
   { id: 'CCPD', label: 'CCPD', value: 'CCPD' }
]

export const StatesDropdownValues = [
   { id: 'AL', label: 'AL', value: 'Alabama' },
   { id: 'AK', label: 'AK', value: 'Alaska' },
   { id: 'AZ', label: 'AZ', value: 'Arizona' },
   { id: 'AR', label: 'AR', value: 'Arkansas' },
   { id: 'CA', label: 'CA', value: 'California' },
   { id: 'CO', label: 'CO', value: 'Colorado' },
   { id: 'CT', label: 'CT', value: 'Connecticut' },
   { id: 'DE', label: 'DE', value: 'Delaware' },
   { id: 'FL', label: 'FL', value: 'Florida' },
   { id: 'GA', label: 'GA', value: 'Georgia' },
   { id: 'HI', label: 'HI', value: 'Hawaii' },
   { id: 'ID', label: 'ID', value: 'Idaho' },
   { id: 'IL', label: 'IL', value: 'Illinois' },
   { id: 'IN', label: 'IN', value: 'Indiana' },
   { id: 'IA', label: 'IA', value: 'Iowa' },
   { id: 'KS', label: 'KS', value: 'Kansas' },
   { id: 'KY', label: 'KY', value: 'Kentucky' },
   { id: 'LA', label: 'LA', value: 'Louisiana' },
   { id: 'ME', label: 'ME', value: 'Maine' },
   { id: 'MD', label: 'MD', value: 'Maryland' },
   { id: 'MA', label: 'MA', value: 'Massachusetts' },
   { id: 'MI', label: 'MI', value: 'Michigan' },
   { id: 'MN', label: 'MN', value: 'Minnesota' },
   { id: 'MS', label: 'MS', value: 'Mississippi' },
   { id: 'MO', label: 'MO', value: 'Missouri' },
   { id: 'MT', label: 'MT', value: 'Montana' },
   { id: 'NE', label: 'NE', value: 'Nebraska' },
   { id: 'NV', label: 'NV', value: 'Nevada' },
   { id: 'NH', label: 'NH', value: 'New Hampshire' },
   { id: 'NJ', label: 'NJ', value: 'New Jersey' },
   { id: 'NM', label: 'NM', value: 'New Mexico' },
   { id: 'NY', label: 'NY', value: 'New York' },
   { id: 'NC', label: 'NC', value: 'North Carolina' },
   { id: 'ND', label: 'ND', value: 'North Dakota' },
   { id: 'OH', label: 'OH', value: 'Ohio' },
   { id: 'OK', label: 'OK', value: 'Oklahoma' },
   { id: 'OR', label: 'OR', value: 'Oregon' },
   { id: 'PA', label: 'PA', value: 'Pennsylvania' },
   { id: 'RI', label: 'RI', value: 'Rhode Island' },
   { id: 'SC', label: 'SC', value: 'South Carolina' },
   { id: 'SD', label: 'SD', value: 'South Dakota' },
   { id: 'TN', label: 'TN', value: 'Tennessee' },
   { id: 'TX', label: 'TX', value: 'Texas' },
   { id: 'UT', label: 'UT', value: 'Utah' },
   { id: 'VT', label: 'VT', value: 'Vermont' },
   { id: 'VA', label: 'VA', value: 'Virginia' },
   { id: 'WA', label: 'WA', value: 'Washington' },
   { id: 'WV', label: 'WV', value: 'West Virginia' },
   { id: 'WI', label: 'WI', value: 'Wisconsin' },
   { id: 'WY', label: 'WY', value: 'Wyoming' }
]

export const IsPatientNewToDialysis = [
   { id: 'New ESRD', label: 'New ESRD', value: 'New ESRD' },
   { id: 'New AKI', label: 'New AKI', value: 'New AKI' },
   { id: 'Transfer', label: 'Transfer', value: 'Transfer' },
   { id: 'Visitor', label: 'Visitor', value: 'Visitor' }
]

export const TreatmentDaysValues = [
   { id: '1', label: 'MWF', value: 'MWF' },
   { id: '2', label: 'TTS', value: 'TTS' }
]

export const TreatmentTimeValues = [
   { id: '1', label: 'Early morning (1st shift)', value: 'Early morning (1st shift)' },
   { id: '2', label: 'Mid-morning (2nd shift)', value: 'Mid-morning (2nd shift)' },
   { id: '3', label: 'Afternoon (3rd shift)', value: 'Afternoon (3rd shift)' },
   { id: '4', label: 'Evening (4th shift)', value: 'Evening (4th shift)' }
]

export const BlankDropdownValue = { label: '', value: '' }

export const SenderTitleDropdownValues = [
   { id: 'Case Manager', label: 'Case Manager', value: 'Case Manager' },
   { id: 'Discharge Planner', label: 'Discharge Planner', value: 'Discharge Planner' },
   { id: 'Office Administrator', label: 'Office Administrator', value: 'Office Administrator' },
   { id: 'Other', label: 'Other', value: 'Other' }
]

export const uploadMaxSize = 5242880;

export const isEmptyValue = (value) => {
   if (value === null || value === undefined) return true;

   if (typeof value === "string") return value === "";

   if (typeof value === "object" && Array.isArray(value) && value.length === 0)
      return true;

   if (typeof value === "object" && Array.isArray(value) && value.length === 1)
      return isEmptyValue(value[0]);

   if (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
      return isEmptyValue(value[0]);

   return false;
};

export const downloadDocument = (fieldName, setIsLoading) => {
   setIsLoading(true)
   getDataWithDocument(`/document/document/${fieldName.id}`)
      .then((res) => {
         const link = document.createElement('a')
         const blob = new Blob([res.data])
         const url = window.URL.createObjectURL(blob)
         link.setAttribute('href', url)
         link.setAttribute('download', fieldName.name)
         document.body.appendChild(link)
         link.click()
         setIsLoading(false)
      })
      .catch((e) => {
         console.log('Error in downloadDocument: ', e)
         setIsLoading(false)
      })
}

export const assignDefaultAutocompleteValue = ({
   stringValue,
   list,
   defaultValueCondition
}) => {

   let newValue = {
      label: stringValue
   }

   if (!defaultValueCondition) {
      const mappedValue = list.filter((item) => item.label === stringValue)[0]

      if (mappedValue?.label) {
         newValue = mappedValue
      }
   }

   return newValue
}

export const isJson = (str) => {
   try {
      JSON.parse(str);
   } catch (e) {
      return false;
   }
   return true;
}

export const generateFormDataFromFileList = (files) => {

   const formData = new FormData();

   if (!files) return formData

   files.forEach(file => {

      formData.append('file', file);
      formData.append('fileName', file.name);
   })

   return formData
}
export function isHTML(str) {
   const doc = new DOMParser().parseFromString(str, "text/html");
   return [].slice.call(doc.body.childNodes).some(node => node.nodeType === 1);
}

export function convertHtmlToPlaintext(str) {
   return str.replace(/<[^>]*>/g, '')
}

export const validateEmail = (emailAddress) => {

   // eslint-disable-next-line
   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      return ''
   } else {
      return 'invalid format'
   }
}

export const PATIENT_TAB_NAME = 'patient'
export const INSURANCE_TAB_NAME = 'insurance'
export const TREATMENT_TAB_NAME = 'treatment'
export const ATTACHMENT_TAB_NAME = 'attachment'
export const REFERRAL_TAB_NAME = 'referral'
