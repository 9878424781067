import axios from 'axios'

const apiUrl = 'https://spinnextgenapidev.shccloud.com'

export const instance = axios.interceptors.response.use(
  (response) => {
    return response
  },

  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // deleteCookie();
      }
    }
    return Promise.reject(error)
  }
)

export function getData(query) {
  return axios({
    method: 'get',
    url: apiUrl + query
  })
}

export function getDataWithUrl(url) {
  return axios({
    method: 'get',
    url
  })
}

export function postMethod(url, data) {
  return axios({
    method: 'post',
    headers: {
      'Content-type': 'application/json'
    },
    url,
    data
  })
}
export function putMethod(query, data) {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: apiUrl + query,
    data
  })
}
export function patchMethod(query, data) {
  return axios({
    method: 'patch',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: apiUrl + query,
    data
  })
}
export function deleteMethod(query) {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: apiUrl + query
  })
}

// Function to call with New URL
export function getDataWithNewURL(query) {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: newApiUrl + query
  })
}
export function getDataWithDocument(query) {
  return axios({
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    responseType: 'blob',
    url: newApiUrl + query
  })
}

export function postMethodWithNewURL(query, data) {
  return axios({
    method: 'post',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: newApiUrl + query,
    data
  })
}
export function postMethodWithUploadURL(query, data) {
  return axios({
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    url: query,
    data
  })
}
export function patchMethodwithNewURL(query, data) {
  return axios({
    method: 'patch',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: newApiUrl + query,
    data
  })
}
export function putMethodWithNewURL(query, data) {
  return axios({
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: newApiUrl + query,
    data
  })
}
export function deleteMethodWithNewURL(query) {
  return axios({
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
    },
    url: newApiUrl + query
  })
}

export function getDataWithCancelToken(query) {
  // eslint-disable-next-line prefer-const
  // const CancelToken = axios.CancelToken;
  // let cancel;

  // return axios({
  //   method: 'get',
  //   headers: {
  //     Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
  //   },
  //   url: newApiUrl + query,
  //   cancelToken: new CancelToken(function executor(c) {
  //     // An executor function receives a cancel function as a parameter
  //     cancel = c;
  //   }) 
  // })
  // eslint-disable-next-line no-unreachable
  // cancel()

  // eslint-disable-next-line prefer-const
  let cancelToken;
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancelToken("Cancelling the previous req")
  }
  cancelToken = axios.CancelToken.source()

  const url = newApiUrl + query
  const res = axios.get(url, {
    cancelToken: cancelToken.token
  });
  return res
}