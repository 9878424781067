import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import BasicSelect from "../../../component/common/BasicSelect";
import InputBox from "../../../component/common/InputBox";
import ZipCodeLookup from "../../../component/common/ZipCodeLookup";
import { PatientDetailsProps } from "../../../types/formTypes";
import { EthnicityDropdownValues, GenderDropdownValues, RaceDropdownValues, validateEmail } from "../../../utils/utilities";

const d = new Date();
const maxDate = new Date(d.getFullYear() - 10, d.getMonth(), d.getDate());

const PatientDetails = ({
   formData,
   triggerSubmitAction,
   triggerResetForm,
   sendDataToParent
}: PatientDetailsProps) => {
   const [isSubmitClicked, setIsSubmitClicked] = useState(false)

   const formik = useFormik({
      initialValues: {
         ...formData
      },
      onSubmit: (values, { setErrors }) => {
         // console.log('hex form')
      },
   });

   const validationSuccess = () => {
      return (
         (formik.values.firstName !== '') &&
         (formik.values.lastName !== '') &&
         (formik.values.birthDate !== '')
      )
   }

   useEffect(() => {
      setIsSubmitClicked(false)
      formik.resetForm()
   }, [triggerResetForm])

   useEffect(() => {
      if (triggerSubmitAction) {
         setIsSubmitClicked(true)
         sendDataToParent(formik.values, !validationSuccess())
      }
   }, [triggerSubmitAction])

   const patientDetailsForm = [
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'First Name',
         name: 'firstName',
         placeHolder: 'Please Enter First Name...',
         value: formik.values.firstName,
         required: true,
         errorMessage: isSubmitClicked && formik.values.firstName === '' ? 'required' : ''
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Middle Name',
         name: 'middleName',
         placeHolder: 'Please Enter Middle Name...',
         value: formik.values.middleName,
         required: false,
         errorMessage: ''
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Last Name',
         name: 'lastName',
         placeHolder: 'Please Enter Last Name...',
         value: formik.values.lastName,
         required: true,
         errorMessage: isSubmitClicked && formik.values.lastName === '' ? 'required' : ''
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'input',
         type: 'date',
         label: 'Date of Birth',
         name: 'birthDate',
         placeHolder: 'MM-DD-YYYY',
         value: formik.values.birthDate,
         required: true,
         errorMessage: isSubmitClicked && formik.values.birthDate === '' ? 'required' : '',
      },
      {
         class: 'col-md-4',
         elementType: 'input',
         type: 'text',
         label: 'Email',
         name: 'email',
         placeHolder: 'Please Enter the Patients Email Address...',
         value: formik.values.email,
         required: false,
         errorMessage: formik.values.email?.length > 0 ? validateEmail(formik.values.email) : ''
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Gender',
         name: 'genderId',
         placeHolder: 'Please Select Gender...',
         value: formik.values.genderId,
         required: false,
         options: GenderDropdownValues
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Race',
         name: 'raceId',
         placeHolder: 'Please Select Race...',
         value: formik.values.raceId,
         required: false,
         options: RaceDropdownValues
      },
      {
         class: 'col-12 col-md-4',
         elementType: 'select',
         label: 'Ethnicity',
         name: 'ethnicityId',
         placeHolder: 'Please Select Ethnicity...',
         value: formik.values.ethnicityId,
         options: EthnicityDropdownValues,
         required: false
      }
   ]

   const handlePatientDetailsChange = (e, formikObjectPassed) => {
      const { name, value } = e.target
      formikObjectPassed.setFieldValue(name, value)
   }

   return (
      <>
         <div id="patient" className="tabcontent pb-0">
            <div className="title">
               <p>Patient Details</p>
            </div>
            <form className="row" onSubmit={formik.handleSubmit}>
               {patientDetailsForm.map((field, index) =>
                  field.label === 'Ethnicity' || field.label === 'Race' ? (
                     <></>
                  ) : (
                     <div className={field.class} key={index}>
                        {field.elementType === 'input' ? (
                           field.name === 'birthDate' ? (
                              <div className="form-group">
                                 <label className="d-flex justify-content-between">
                                    <div className="d-flex">
                                       {field.label} <span className="required">{field.required && '*'}</span>
                                    </div>
                                    {field.errorMessage && <span style={{ color: '#dc3545' }}>{field.errorMessage}</span>}
                                 </label>
                                 <input
                                    type="date"
                                    className="form-control"
                                    name={field.name}
                                    value={field.value}
                                    onChange={(e) => handlePatientDetailsChange(e, formik)}
                                    placeholder={field.placeHolder}
                                    max={moment(maxDate).format('YYYY-MM-DD')}
                                 />
                              </div>
                           ) : (
                              <InputBox
                                 type={field.type}
                                 label={field.label}
                                 name={field.name}
                                 value={field.value}
                                 placeholder={field.placeHolder}
                                 handleChange={(e) => handlePatientDetailsChange(e, formik)}
                                 required={field.required}
                                 errorMessage={field.errorMessage}
                              />
                           )
                        ) : (
                           <div className="form-group">
                              <label className="d-flex justify-content-between">
                                 <div className="d-flex">
                                    {field.label} <span className="required">{field.required && '*'}</span>
                                 </div>
                                 {field.errorMessage && <span style={{ color: '#dc3545' }}>{field.errorMessage}</span>}
                              </label>
                              {field.elementType === 'select' ? (
                                 <BasicSelect
                                    name={field.name}
                                    label={field.label}
                                    value={field.value}
                                    defaultOption={field.placeHolder}
                                    options={field.options}
                                    handleChange={(e) => {
                                       handlePatientDetailsChange(e, formik)
                                    }}
                                    showLabelSection={false}
                                 />
                              ) : (
                                 <></>
                              )}
                           </div>
                        )}
                     </div>
                  )
               )}
               <div className="form-group col-md-4">
                  {patientDetailsForm.map((field, index) =>
                     field.label === 'Ethnicity' || field.label === 'Race' ? (
                        <div className="form-group">
                           <label className="d-flex justify-content-between">
                              <div className="d-flex">
                                 {field.label} <span className="required">{field.required && '*'}</span>
                              </div>
                              {field.errorMessage && <span style={{ color: '#dc3545' }}>{field.errorMessage}</span>}
                           </label>
                           {field.elementType === 'select' ? (
                              <BasicSelect
                                 name={field.name}
                                 label={field.label}
                                 value={field.value}
                                 defaultOption={field.placeHolder}
                                 options={field.options}
                                 handleChange={(e) => {
                                    handlePatientDetailsChange(e, formik)
                                 }}
                                 showLabelSection={false}
                              />
                           ) : (
                              <></>
                           )}
                        </div>
                     ) : (
                        <></>
                     )
                  )}
               </div>
               <div className="form-group col-md-8">
                  <label htmlFor="exampleInputEmail1">Address </label>
                  <ZipCodeLookup
                     isPatientForm={true}
                     data={formik.values?.address}
                     handleSetData={(e) => {
                        handlePatientDetailsChange({ target: { name: 'address', value: e } }, formik)
                     }}
                  />
               </div>
            </form>
         </div>
      </>
   )
}

export default PatientDetails;
