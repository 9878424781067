import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import InputBox from "../../../component/common/InputBox";
import { InsuranceDetailsProps } from "../../../types/formTypes";

const d = new Date();
const maxDate = new Date(d.getFullYear() - 10, d.getMonth(), d.getDate());

const InsuranceDetails = ({
    formData,
    triggerSubmitAction,
    triggerResetForm,
    sendDataToParent
}: InsuranceDetailsProps) => {

    const [isSubmitClicked, setIsSubmitClicked] = useState(false)

    const formik = useFormik({
        initialValues: {
            ...formData
        },
        onSubmit: (values, { setErrors }) => {
            // console.log('hex form')
        },
    });

    useEffect(() => {
        setIsSubmitClicked(false)
        formik.resetForm()
      }, [triggerResetForm])

    useEffect(() => {
        if (triggerSubmitAction) {
            setIsSubmitClicked(true)
            sendDataToParent(formik.values)
        }
    }, [triggerSubmitAction])

    const InsuranceDetailsForm = [
        {
            class: 'col-12 col-md-4',
            elementType: 'input',
            type: 'text',
            label: 'Insurance Name',
            name: 'insuranceName',
            placeHolder: 'Please Enter Insurance Name...',
            value: formik.values.insuranceName,
            required: false
        },
        {
            class: 'col-12 col-md-4',
            elementType: 'input',
            type: 'text',
            label: 'Insurance ID',
            name: 'insuranceId',
            placeHolder: 'Please Enter Insurance ID...',
            value: formik.values.insuranceId,
            required: false
        },
        {
            class: 'col-12 col-md-4',
            elementType: 'input',
            type: 'date',
            label: 'Effective Date',
            name: 'effectiveDate',
            placeHolder: 'MM-DD-YYYY',
            value: formik.values.effectiveDate,
            required: false
        }
    ]

    const handleInsuranceDetailsChange = (e, formikObjectPassed) => {
        const { name, value } = e.target

        formikObjectPassed.setFieldValue(name, value)
    }

    return (
        <>
            <div id="insurance" className="tabcontent pb-0">
                <div className="title">
                    <p>Insurance Details</p>
                </div>
                <div>
                    <form className="row">
                        {InsuranceDetailsForm.map((field, index) => (
                            <div className={field.class} key={index}>
                                {field.elementType === 'input' ? (
                                    field.name === 'birthDate' ? (
                                        <div className="form-group">
                                            <label className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    {field.label} <span className="required">{field.required && '*'}</span>
                                                </div>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name={field.name}
                                                value={field.value}
                                                onChange={(e) => handleInsuranceDetailsChange(e, formik)}
                                                placeholder={field.placeHolder}
                                                max={moment(maxDate).format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    ) : (
                                        <InputBox
                                            type={field.type}
                                            label={field.label}
                                            name={field.name}
                                            value={field.value}
                                            handleChange={(e) => handleInsuranceDetailsChange(e, formik)}
                                            placeholder={field.placeHolder}
                                            required={field.required}
                                        />
                                    )
                                ) : <></>}
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </>
    );
}

export default InsuranceDetails;