/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { Children, useEffect, useState } from 'react'
import { Tab, Tabs, Box, Typography } from '@mui/material'
import { Badge } from 'react-bootstrap'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface tabTileObj {
  index: number
  count: number
}

interface TabsProps {
  tabsFilterIndex?: number
  handleChangeTabs?: () => any
  tabData: any[],
  tabPanel: any[]
  children?: React.ReactNode,
  setTabsFilterIndex: React.Dispatch<React.SetStateAction<number>>
  tabItemsCountDetails?: tabTileObj[]
  classNamePassed?: string
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const CommonTab: React.FC<TabsProps> = ({ tabsFilterIndex, handleChangeTabs, setTabsFilterIndex, tabData, children, tabPanel, tabItemsCountDetails, classNamePassed = '' }) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (isNaN(Number(tabsFilterIndex))) {
      setValue(Number(tabsFilterIndex))
    }
  }, [tabsFilterIndex])

  return (
    <>
      <div className={classNamePassed}>
        <Tabs value={tabsFilterIndex} onChange={handleChangeTabs} variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile={true}
        >
          {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            tabData &&
            tabData.length > 0 &&
            tabData?.map((tabsValue: any, i: any) => (
              <Tab
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                key={`tabs- ${i}`}
                onClick={e => setTabsFilterIndex(i)}
                label={
                  <div>
                    <a data-toggle="tab" onClick={e => e.preventDefault()}>{tabsValue.name}</a>
                  </div>
                }
              />
            ))
          }
        </Tabs>
        {
          tabPanel.map((panelValue, i) => (
            <TabPanel key={`tabspanel- ${i}`} value={(Number(tabsFilterIndex))} index={i}>
              {panelValue}
            </TabPanel>
          ))
        }
      </div>
    </>
  )
}

CommonTab.defaultProps = {
  tabsFilterIndex: 0
}

export default CommonTab
