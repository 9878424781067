import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import './style.css'

export default function Loader() {
  return (
    <div className="loading">
      <Box sx={{ display: 'flex' }} className="loader">
        <CircularProgress />
      </Box>
    </div>
  )
}
