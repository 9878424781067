/* eslint-disable @typescript-eslint/restrict-template-expressions */


import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Attachments from "../../../component/common/Attachments";
import CommonTab from "../../../component/common/CommonTab";
import { AttachmentDetailsProps } from "../../../types/formTypes";

export const TabNames = [
   { name: 'Face Sheet' },
   { name: 'Demographics sheet' },
   { name: 'Hep B' },
   { name: 'H&P' },
   { name: 'Lab Workup' },
   { name: 'Discharge Report' },
   { name: 'Other' }
]

const supportedFileFormats = {
   'image/*': ['.jpg', '.png'],
   'application/pdf': ['.pdf'],
   'text/csv': ['.csv']
}

const AttachmentsTab = ({
   formData,
   triggerSubmitAction,
   triggerResetForm,
   sendDataToParent
}: AttachmentDetailsProps) => {

   const [isSubmitClicked, setIsSubmitClicked] = useState(false)
   const [detailsTabIndex, setDetailsTabIndex] = useState(0)

   const handleChangeDetailsTab: any = (event: React.SyntheticEvent, newValue: number) => {
      setDetailsTabIndex(newValue);
   }

   const formik = useFormik({
      initialValues: {
         ...formData
      },
      onSubmit: (values, { setErrors }) => {
      },
   });

   useEffect(() => {
      setIsSubmitClicked(false)
      formik.resetForm()
    }, [triggerResetForm])

   useEffect(() => {
      if (triggerSubmitAction) {
         setIsSubmitClicked(true)
         sendDataToParent(formik.values)
      }
   }, [triggerSubmitAction])

   const tabPanelSection = [
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.faceSheet}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'faceSheet', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.demographicsSheet}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'demographicsSheet', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.hepB}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'hepB', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.hAndP}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'hAndP', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.labWorkup}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'labWorkup', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.dischargeReport}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'dischargeReport', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
      <Attachments
         showUploadSection={true}
         isNewInventory={true}
         filesData={formik.values.other}
         setAppState={(data) => {
            handlePatientDetailsChange({ target: { name: 'other', value: [...data] } }, formik)
         }}
         supportedFormats={supportedFileFormats}
      />,
   ]

   const handlePatientDetailsChange = (e, formikObjectPassed) => {
      const { name, value } = e.target

      formikObjectPassed.setFieldValue(name, value)
   }


   return (
      <>
         <div id="attachment" className="tabcontent pb-0 app-main-tab-block">
            <div className="title">
               <p>Attachments</p>
            </div>
            <div className="form-group file-area mt-4 mb-0">

               <div id="exTab2" className="container p-0">
                  <CommonTab
                     classNamePassed={'app-main-tab-block mt-3 modal-inner-tab'}
                     tabData={TabNames}
                     tabsFilterIndex={detailsTabIndex}
                     tabItemsCountDetails={[
                        {
                           index: 0,
                           count: Array.isArray(formik.values.faceSheet) ? formik.values.faceSheet.length : 0
                        },
                        {
                           index: 1,
                           count: Array.isArray(formik.values.demographicsSheet) ? formik.values.demographicsSheet.length : 0
                        },
                        {
                           index: 2,
                           count: Array.isArray(formik.values.demographicsSheet) ? formik.values.demographicsSheet.length : 0
                        },
                        {
                           index: 3,
                           count: Array.isArray(formik.values.demographicsSheet) ? formik.values.demographicsSheet.length : 0
                        },
                        {
                           index: 4,
                           count: Array.isArray(formik.values.demographicsSheet) ? formik.values.demographicsSheet.length : 0
                        },
                     ]}
                     setTabsFilterIndex={setDetailsTabIndex}
                     handleChangeTabs={handleChangeDetailsTab}
                     tabPanel={tabPanelSection}
                  />

                  <div className="tab-content ">
                     <div className="tab-pane active position-relative" id="1">

                        <div className="pt-3 row">
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </div>

      </>
   );
}

export default AttachmentsTab;