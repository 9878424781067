import { useState } from 'react'

export const DEFAULT_STATE = {
  currentPageNo: 1,
  currentPageSize: 10,
  searchString: '',
  sortingDetails: {
    sortByCol: 'PatientName',
    sortOrder: 'asc'
  },
  isStatusUpdated: false,
  rowIndex: null,
  searchText: '',
  patientName: '',
  status: '',
  isStartProcessHaveConflict: false,
  viewNotificationID: '',
  userRole: '',
  showSnackBar: false,
  snackBarMsg: '',
  snackMsgType: '',
  toggleNewWorkItemWindow: false,
  toggleEditWorkItemWindow: false,
  toggleViewWorkItemWindow: false,
  toggleViewPatient: false,
  toggleEditPatient: false,
  toggleNewPatient: false,
  patientSelected: null,
  workItemRowClicked: null,
  userId: ''
  // uiElementPermissions : []
}

// Setting default app state
export const useAppState = () => {
  const [appState, setAppState] = useState({
    ...DEFAULT_STATE
  })

  return [appState, setAppState]
}
