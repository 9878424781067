/* eslint-disable @typescript-eslint/strict-boolean-expressions */

interface InputBoxProps {
  name?: string
  value?: string | number | any
  handleChange?: Function
  label: string
  type?: string
  required?: boolean
  placeholder?: string
  errorMessage?: string
  readOnly?: boolean,
  maxLength?: number | undefined
  minLength?: number | undefined
}

function InputBox({
  label,
  type = 'text',
  required = false,
  placeholder = label,
  name,
  value,
  handleChange,
  errorMessage = '',
  readOnly = false,
  maxLength,
  minLength
}: InputBoxProps, props) {

  const conditionalProps: any = {}

  if(maxLength) {
    conditionalProps.maxLength = maxLength
  }
  if(minLength) {
    conditionalProps.minLength = minLength
  }
  
  return (
    <div className={`form-group`}>
      {
        (label || errorMessage || required) &&
        <label className="d-flex justify-content-between">
          <div className="d-flex">
            {label}
            {required && <span className="required">*</span>}
          </div>
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {errorMessage && <span style={{ color: '#dc3545' }}>{errorMessage}</span>}
        </label>
      }
      <input
        type={type}
        readOnly={false}
        className="form-control"
        name={name}
        value={value}
        onChange={(e) => handleChange?.(e)}
        placeholder={placeholder}
        // required={required}
        // {...props}
        {...conditionalProps}
      />
    </div>
  )
}

export default InputBox
