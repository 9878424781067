import { useEffect, useState } from 'react'
const Checkbox = (props) => {
  const { label, checked, value, changeCheckbox, parentId } = props
  const defaultChecked = false
  const [isChecked, setIsChecked] = useState(defaultChecked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const changedValue = (event) => {
    setIsChecked((prev) => !prev)
    if (changeCheckbox) {
      changeCheckbox(!isChecked, event.target.value, parentId)
    }
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          value={value}
          onChange={(e) => changedValue(e)}
          className={isChecked ? 'checked' : ''}
          {...props}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
export default Checkbox
